.carNumber {
  .select {
    width: 57px;
    margin-right: 10px;
  }

  input {
    width: 0;
    flex: 1;
  }
}

.prefixDropdown {
  width: 308px;
  height: 136px;

  & > div {
    height: 100%;

    & > div:last-child {
      margin-right: 4px;
      height: 100%;

      & > div {
        height: 100%;

        & > div > div {
          flex-direction: row !important;
          flex-wrap: wrap;

          & > div {
            color: #6a6a6a !important;
            background-repeat: no-repeat;
            background-size: 14px 10px;
            background-position: right center;
          }
        }
      }
    }
  }

  :global {
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      color: #1989fa !important;
      background-color: transparent;
    }

    .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
      background-color: #f5f7fa;
    }
  }
}

@s-p: #1890ff;@s-s: #52c41a;@s-w: #faad14;@s-e: #f5222d;@s-fs: 16px;@s-c: rgba(0, 0, 0, 0.85);@s-cs: rgba(0, 0, 0, .45);@s-dc: rgba(0, 0, 0, .25);@s-dcb: rgba(0, 0, 0, .05);@s-bc: #eee;@s-bcd: #ccc;@s-b: #fff;