.login {
  & > p {
    font-size: 32px;
    margin-top: 50px;
    margin-bottom: 30px;
  }
}

.form {
  width: 320px;
}

.verifyCode {
  flex-shrink: 0;
  width: 128px;
  height: 40px;
  margin-left: 5px;
  background-color: #f1f1f1;
  overflow: hidden;
  position: relative;

  img[src=''],
  img:not([src]) {
    opacity: 0;
  }
}

.button {
  margin-top: 20px;
}

@s-p: #1890ff;@s-s: #52c41a;@s-w: #faad14;@s-e: #f5222d;@s-fs: 16px;@s-c: rgba(0, 0, 0, 0.85);@s-cs: rgba(0, 0, 0, .45);@s-dc: rgba(0, 0, 0, .25);@s-dcb: rgba(0, 0, 0, .05);@s-bc: #eee;@s-bcd: #ccc;@s-b: #fff;