/*
  主题色
  '@s-p': '#1890ff', // 全局主色
  '@s-s': '#52c41a', // 成功色
  '@s-w': ' #faad14', // 警告色
  '@s-e': '#f5222d', // 错误色
  '@s-fs': '14px', // 主字号
  '@s-c': 'rgba(0, 0, 0, 0.85)', // 主文本色
  '@s-cs': 'rgba(0, 0, 0, .45)', // 次文本色
  '@s-dc': 'rgba(0, 0, 0, .25)', // 失效色
  '@s-dcb': 'rgba(0, 0, 0, .05)', // 失效背景色
  '@s-bc': '#eeeeee', // 边框色
  '@s-bcd': '#cccccc', // 边框色-深色
  '@s-b': '#f1f1f1', // 背景色
*/

/************************** 常用类 **************************/

.fill() {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

// 填满
.fill {
  position: absolute;
  .fill();
}

.fill-fixed {
  position: fixed;
  .fill();
}

// 上面对齐
.align-top {
  display: flex;
  align-items: flex-start;
}

// 上下居中
.align-center {
  display: flex;
  align-items: center;
}

// 内容居中
.center {
  .align-center;
  justify-content: center;
}

// 间隔均分
.between {
  display: flex;
  justify-content: space-between;
}

// 间隔均分 并上下居中
.between-center {
  .between;
  align-items: center;
}

// 纵向排列
.column {
  display: flex;
  align-items: stretch;
  flex-direction: column;
}

// 上面对齐
.column-left {
  .align-top;
  flex-direction: column;
}

// 纵向居中
.column-center {
  .center;
  flex-direction: column;
}

.transition {
  transition: all 0.3s;
}

.overflow {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.pointer {
  cursor: pointer;
}

/************************** 常用类 **************************/

/************************** 常用字颜色 **************************/

.primary,
.edit {
  color: @s-p;
}

.success,
.details {
  color: @s-s;
}

.warning {
  color: @s-w;
}

.error,
.delete {
  color: @s-e;
}

.secondary {
  color: @s-cs;
}

.disabled {
  color: @s-dc;
}

/************************** 常用字颜色 **************************/

/************************** 页面默认布局 **************************/

// 根标签
#root {
  .fill;
  overflow: hidden;
  line-height: 1;
  font-size: @s-fs;
  background-color: @s-b;
}

// 弹性盒子布局
.box(@name) {
  .column;

  // 防止压缩
  & > :not(.@{name}-fill) {
    flex-shrink: 0;
  }

  // 自动填满
  & > .@{name}-fill {
    .column;
    .overflow;
    flex: 1;
    position: relative;

    & > * {
      flex-shrink: 0;
    }
  }
}

.box {
  .box(box);
}

// 页面布局 填满并加入弹性盒子,并可滚动
.page {
  .fill;
  .box(page);
  .overflow;
  box-sizing: border-box;
}

/************************** 页面默认布局 **************************/

@s-p: #1890ff;@s-s: #52c41a;@s-w: #faad14;@s-e: #f5222d;@s-fs: 16px;@s-c: rgba(0, 0, 0, 0.85);@s-cs: rgba(0, 0, 0, .45);@s-dc: rgba(0, 0, 0, .25);@s-dcb: rgba(0, 0, 0, .05);@s-bc: #eee;@s-bcd: #ccc;@s-b: #fff;