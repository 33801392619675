.steps {
  :global {
    .ant-steps-item-description {
      padding-top: 20px;
    }
  }
}

.mortgageeInfo {
  font-size: 12px;

  .img {
    background-color: #dde1e0;
    width: 100px;
    height: 60px;
  }
}

@s-p: #1890ff;@s-s: #52c41a;@s-w: #faad14;@s-e: #f5222d;@s-fs: 16px;@s-c: rgba(0, 0, 0, 0.85);@s-cs: rgba(0, 0, 0, .45);@s-dc: rgba(0, 0, 0, .25);@s-dcb: rgba(0, 0, 0, .05);@s-bc: #eee;@s-bcd: #ccc;@s-b: #fff;