@size: 80px;

.uploadButton {
  width: @size;
  height: @size;
  font-size: 12px;
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;

  .icon {
    font-size: 30px;
    margin: 8px 0;
  }
}

.uploadPreview {
  position: relative;
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;

  &,
  .image {
    width: @size;
    height: @size;
  }

  .error {
    svg {
      font-size: 55px;
    }
  }

  .del {
    opacity: 0;
    position: absolute;
    right: -5px;
    top: -5px;
    background-color: #fff;
    border-radius: 50%;
    z-index: 1;
  }

  &:hover {
    .del {
      opacity: 1;
    }
  }
}

.pictureGroup {
  display: flex;
  flex-wrap: wrap;
}

@s-p: #1890ff;@s-s: #52c41a;@s-w: #faad14;@s-e: #f5222d;@s-fs: 16px;@s-c: rgba(0, 0, 0, 0.85);@s-cs: rgba(0, 0, 0, .45);@s-dc: rgba(0, 0, 0, .25);@s-dcb: rgba(0, 0, 0, .05);@s-bc: #eee;@s-bcd: #ccc;@s-b: #fff;