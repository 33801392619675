.alipay {
  font-size: 12px;
  font-weight: 700;
  color: #018bde;
  border: 1px solid #018bde;
  border-radius: 3px;
  width: 160px;
  height: 48px;

  img {
    width: 28px;
    margin-right: 10px;
  }
}

.bankCardTransfer {
  display: flex;
  flex-wrap: wrap;

  & > div {
    flex: 1;
    min-width: 375px;
    margin-bottom: 30px;
  }
}

.bankCard {
  width: 320px;
  border: 1px solid #d0d0d0;
  padding: 20px 0;
  margin: 15px 0;

  .label {
    width: 80px;
    display: inline-block;
    text-align: right;
    margin-right: 10px;
    font-weight: 700;
  }
}

@s-p: #1890ff;@s-s: #52c41a;@s-w: #faad14;@s-e: #f5222d;@s-fs: 16px;@s-c: rgba(0, 0, 0, 0.85);@s-cs: rgba(0, 0, 0, .45);@s-dc: rgba(0, 0, 0, .25);@s-dcb: rgba(0, 0, 0, .05);@s-bc: #eee;@s-bcd: #ccc;@s-b: #fff;