.formSearch {
  :global {
    .ant-form-item {
      margin-bottom: 8px;
    }

    .ant-select,
    .ant-cascader-picker {
      min-width: 100px;
    }

    .ant-checkbox-group {
      line-height: 32px;
    }
  }
}

.virtualColumn {
  border-bottom: 1px solid #f0f0f0;
}

.table {
  overflow: visible !important;

  :global {
    .ant-table-row-expand-icon {
      vertical-align: -1px;
    }
  }
}

.columnsSetting {
  & > span {
    font-size: 12px;
    transform-origin: right;
    transform: scale(0.8);
  }

  :global {
    .ant-checkbox-wrapper {
      transform-origin: left;
      transform: scale(0.8);
    }
  }
}

.tablePageHeader {
  box-shadow: none;
}

@s-p: #1890ff;@s-s: #52c41a;@s-w: #faad14;@s-e: #f5222d;@s-fs: 16px;@s-c: rgba(0, 0, 0, 0.85);@s-cs: rgba(0, 0, 0, .45);@s-dc: rgba(0, 0, 0, .25);@s-dcb: rgba(0, 0, 0, .05);@s-bc: #eee;@s-bcd: #ccc;@s-b: #fff;